import React from 'react';
import { graphql } from 'gatsby';
import OnVisible from 'react-on-visible';
import Previewable from '../components/Previewable';
import Layout from '../components/Layout';
import { RelatedProjects } from '../acf/RelatedProjects';
import { Banner } from '../acf/Banner';
import { Carousel } from '../components/Carousel';
import Image from '../components/Image';
import SEO from '../components/SEO';
import { decodeEntities } from '../utils/htmlParse';
import './project.scss';

const Project = ({ data }) => {
  const { wordpressWpProjects: project, site, location, relatedProjects } = data;
  if (!project) return null;
  const { title, slug, content, tags, featuredImage, acf, yoast } = project;
  const { projectLocation, designers, size, completionDate, duration, slideshow } = acf;
  const { title: siteTitle, showcaseSlug } = site.siteMetadata;
  const isSaf = tags && tags.some(tag => tag.name === 'saf');
  const breadcrumbs = [{
    pageLink: '/showcase/',
    crumbText: 'Showcase'
  }];
  const slideshowItems = (slideshow && slideshow.length > 0) ? slideshow.filter(slide => slide.image !== null) : null;
  return (
    <Layout className={`project-page-template ${isSaf ? 'saf' : ''}`} location={location}>
      <SEO
        title={`${
          yoast.metaTitle ?
          decodeEntities(yoast.metaTitle) :
          `${decodeEntities(title)} | ${decodeEntities(siteTitle)}`
        }`}
        desc={yoast.metaDescription}
      />
      <Banner
        title={title}
        image={featuredImage}
        breadcrumbs={breadcrumbs}
        hideHeading
      />
      <section className="project-content-main">
        <div className="wrapper">
          <OnVisible className="project-details">
            <p>
              <span>Location: </span>
              {decodeEntities(projectLocation)}
            </p>
            {designers && (
              <p>
                <span>Designers: </span>
                {decodeEntities(designers)}
              </p>
            )}
            {size && (
              <p>
                <span>Size: </span>
                {decodeEntities(size)}
              </p>
            )}
            {completionDate && (
              <p>
                <span>Completion: </span>
                {decodeEntities(completionDate)}
              </p>
            )}
            {duration && (
              <p>
                <span>Duration: </span>
                {decodeEntities(duration)}
              </p>
            )}
          </OnVisible>
          <div className="project-content">
            <h1 dangerouslySetInnerHTML={{__html: title}} />
            <div className="content" dangerouslySetInnerHTML={{__html: content}} />
          </div>
        </div>
      </section>
      {(slideshowItems && slideshowItems.length > 0) && (
        <section className="project-gallery-slider">
          <div className="wrapper">
            <Carousel
              // eslint-disable-next-line
              customPaging={(i) => <span>{i + 1}/{slideshowItems.length}</span>}
            >
              {slideshowItems.map((slide, slideIndex) => (
                <React.Fragment key={`${slug}--${slideIndex}`}>
                  <Image className="slide-image" image={slide.image} />
                </React.Fragment>
              ))}
            </Carousel>
          </div>
        </section>
      )}
      {relatedProjects.edges.length > 0 && (
        <RelatedProjects
          title="Related Projects"
          relatedProjects={relatedProjects}
          showcaseSlug={showcaseSlug}
        />
      )}
    </Layout>
  )
}

export default Previewable(Project)

export const pageQuery = graphql`
  query ProjectByID($id: String!, $projectTypeId: Int) {
    site {
      siteMetadata {
        title
        showcaseSlug
        functionsUrl
      }
    }
    wordpressWpProjects(id: { eq: $id }) {
      id
      title
      slug
      tags {
        name
      }
      content
      acf {
        projectLocation
        designers
        size
        completionDate
        duration
        slideshow {
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 70) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
      yoast {
        metaTitle: title,
        metaDescription: metadesc
      }
      featuredImage: featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    relatedProjects: allWordpressWpProjects(filter: { projecttype: { in: [$projectTypeId] }, id: { ne: $id }}, limit: 3) {
      edges {
        node {
          title
          content
          slug
          featuredImage: featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
